import React from 'react'
import './dashboard.css'


 const View = (props) => {
    return (
        <div>
            <form>
                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="customerName">Customer Name</label>
                        <input type="text" class="form-control" id="customerName" disabled value={props.dealValue.details.customerName} />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="contactNumber">Contact Number</label>
                        <input type="text" class="form-control" id="contactNumber" disabled value={props.dealValue.details.contactNumber} />
                    </div>
                </div>

                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="resourceID">Resource ID</label>
                        <input type="text" class="form-control" id="resourceID" disabled value={props.dealValue.details.resourceID} />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="serviceName">Service Name</label>
                        <input disabled  type="text" class="form-control" id="serviceName"  value={props.dealValue.details.serviceName} />
                    </div>
                </div>

                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="amount">Amount</label>
                        <input type="number" class="form-control" id="amount" disabled value={props.dealValue.details.amount} />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="paymentStatus">Payment Status</label>
                        <select disabled id="inputState" class="form-control">
                             <option value="paid">Paid</option>
                            <option value="pending">Pending</option>
                        </select>
                    </div>
                    
                </div>
                
            </form>
        </div>
    )
}

export default View