
import React, { Fragment, useEffect, useState } from 'react';
import MiniDrawer from '../navbar/Navbar'
import Button from '@material-ui/core/Button';

import { Modal } from 'react-responsive-modal';
import '../../../node_modules/react-responsive-modal/styles.css'
import firebase from 'firebase/app'

import BootstrapTable from "react-bootstrap-table-next";
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';


import { css } from "@emotion/core";
import {PropagateLoader} from "react-spinners";
import './dashboard.css'

import Form from './editForm'
import View from './view'
 import DealForm from './dealForm' 
export default function Dashboard() {
  const override = css`
  display: block;
  margin-left:50%;
 `;

    const [open, setOpen] = React.useState(false);
    const [openView, setOpenView] = React.useState(false);
    const [openForm, setOpenForm] = React.useState(false);
    const [data, setData] = React.useState("")
    const [ dealValue, setDealValue] = useState("")
    const [loading, setLoading] = useState(false)
    const [saved, setSaved] = useState(false)
    const [error, setError] = useState("")
    const [verified, setVerified] = useState("")
    const [user, setUser] = useState("")
    const [emailSent, setEmailSent] = useState("")
    const [role, setRole] = useState("")

    const [dummy, setDummy] = useState(false)
    useEffect(() => {
      
      var functions = firebase.app().functions('asia-east2');
      setLoading(true)
      const getOrders = functions.httpsCallable('getOrders');
       getOrders()
        .then((response) => {
          setRole(response.data.role)  
          setData(response.data.orders)
          setLoading(false)
        })
       .catch( (err)=>{setError(err.code) 
        console.log((err))
        setLoading(false)}) 
        checkMail()
    }, [saved, dummy])




    useEffect ( () =>{
      firebase.auth().onAuthStateChanged((user) => {
        if(user) {
          setVerified(user.emailVerified)
          setUser(user)
        }
       })
    }, [])


    const checkMail = () => {
      if(localStorage.getItem("email") === null){
        setEmailSent(false)
      }else{
        setEmailSent(true)
      }
    }

    const onOpenModal = (rowData) => {
      setOpen(true);
      setDealValue(rowData)
    };
   
     const onCloseModal = () => {
      setOpen(false);
    };

    const onOpenForm = () => {
      setOpenForm(true);
    };
   
     const onCloseForm = () => {
      setOpenForm(false);
    };

    const onOpenView = (rowData) => {
      setOpenView(true);
      setDealValue(rowData)
    };
   
     const onCloseView = () => {
      setOpenView(false);
    };


       const columns = [
        {
          dataField: "details.resourceID",
          text: "Resource Id"
        },
        {
          dataField: "details.customerName",
          text: "Customer Name",
        },
        {
          dataField:"details.serviceName",
          text:"Service"
        },
        {
          dataField:"details.amount",
          text:"Amount",
          sort:true
        },
        {
          dataField:"details.paymentStatus",
          text:"Payment Status"
        },
        {
            dataField: "id",
            text: "Operations",
            formatter: (cell, row) => {
              switch(role) {
                case "admin":
                  return (
                    <>
                      <VisibilityTwoToneIcon  style={{fontSize:"25",marginRight:"20px", cursor:"pointer"}} onClick={() =>onOpenView(row)}/>
                      <EditTwoToneIcon  style={{fontSize:"25", cursor:"pointer"}} onClick={() =>onOpenModal(row)}/>
                    </>
                  
                  )
                  break;
                case "ops":
                  return (
                    <>
                      <VisibilityTwoToneIcon  style={{fontSize:"25",marginRight:"20px", cursor:"pointer"}} onClick={() =>onOpenView(row)}/>
                      <EditTwoToneIcon  style={{fontSize:"25", cursor:"pointer"}} onClick={() =>onOpenModal(row)}/>
                    </>
                  
                  )
                break;
                default:
                  return ""
              }
            }
          }
       ];

       const sendVerification = () => {
          user.sendEmailVerification()
          setEmailSent(true)
          localStorage.setItem("email", true)
          setDummy(true)
       }

      
    return (
        <Fragment>
        {console.log(emailSent)}
            <MiniDrawer/>
            {console.log(error)}
            <div style={{marginTop:"10vh",maxWidth:"80%", marginLeft:"20ch"}}>
                {
                  error ? 
                  <>
                  <h1>{error.toUpperCase()}</h1>
                  <p>Is your email verified?</p>
                  <p>Or try logging in again</p>
                  {
                    (!verified) &&
                    emailSent ?
                      <>
                      <h4>Please check your mail to verify</h4>
                      <h6>If you still see permission denied please do a re-login</h6>
                      </>
                     :
                    <button onClick={sendVerification} className="verification">Send Verification email</button> 

                  }
                  </>
                  :
                  loading ?
                  <PropagateLoader
                    css={override}
                    size={22}
                    color={"blue"}
                    loading={loading}
                  />
                   : 
                   <>
                   <h1>Dashboard</h1>

                   {role === "ops" || role === "admin" && 
                      <Button 
                      className="create-button" 
                      style={{marginBottom:"1.5vh"}} 
                      variant="contained" 
                      type="button" 
                      color="primary" 
                      onClick={onOpenForm} 
                      >
                      Create Deal
                    </Button>
                  }

                  <BootstrapTable
                    keyField="id"
                    data={data}
                    columns={columns}
                    striped
                    hover
                    condensed
                />   
                   </>   
                }
            </div>

            <Modal open={open} onClose={onCloseModal} center>
                <h2>Edit Deal Data</h2>
                <Form dealValue={dealValue} setSaved={setSaved} setOpen={setOpen} />
            </Modal>

            <Modal open={openView} onClose={onCloseView} center>
                <h2> Deal Data</h2>
                <View dealValue={dealValue} setSaved={setSaved} setOpen={setOpen} />
            </Modal>

            <Modal open={openForm} onClose={onCloseForm} center>
                <h2>Create New Deal</h2>
                <DealForm  setSaved={setSaved} setOpen={setOpenForm} />
            </Modal>
        </Fragment>
    )
}
