import React, {useState} from 'react'
import firebase from 'firebase/app'

import { css } from "@emotion/core";
import {PropagateLoader} from "react-spinners";
import './dashboard.css'


 const Form = (props) => {
    const override = css`
    display: block;
    margin-left:50%;
    margin-bottom:5%
   `;


    const [name, setName] = useState(props.dealValue.details.name)
    const [minimumTransaction, setMinimumTransaction] = useState(props.dealValue.details.minimumTransaction)
    const [type, setType] = useState(props.dealValue.details.type)
    const [discount, setDiscount] = useState(props.dealValue.details.discount)
    const [loading, setLoading] = useState(false)




    const onSubmit = event => {
        setLoading(true)
        event.preventDefault()
        const data = {
            id:props.dealValue.id,
            data:{
                name:name,
                minimumTransaction: minimumTransaction,
                type:type,
                discount:discount
            }
        }
        var functions = firebase.app().functions('asia-east2');
        const updateCoupon = functions.httpsCallable('updateCoupon');
        updateCoupon(data)
        .then(response => {
            console.log(response)
            setLoading(false)
            props.setSaved(true)
            props.setOpen(false)
        }).catch(err => console.log(err))
    }


    return (
        <div>
            <form>
            <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="discount">Discount</label>
                        <input type="text" value={discount} onChange={(e) => setDiscount(e.target.value)} class="form-control" id="discount"  />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="minimumTransaction">Minimum Transaction</label>
                        <input type="text" value={minimumTransaction} onChange={(e) => setMinimumTransaction(e.target.value)} class="form-control" id="minimumTransaction" />
                    </div>
                </div>

                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="name">Name</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} class="form-control" id="name"  />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="type">Coupon Type</label>
                        <select onChange={(e) => setType(e.target.value)} id="type" class="form-control">
                        <option value="">Select</option>
                            <option value="FLAT">Flat</option>
                            <option value="PERCENT">Percent</option>
                        </select>
                    </div>
                </div>

             
                {
                    loading ? 
                    <button type="submit" disabled  style={{float:"right"}} class="btn btn-primary">Save</button>
                    :
                    <button type="submit" onClick={onSubmit} style={{float:"right"}} class="btn btn-primary">Save</button>
                }
                {loading &&     <PropagateLoader
                    css={override}
                    size={22}
                    color={"blue"}
                    loading={loading}
                  />}
            </form>
        </div>
    )
}

export default Form