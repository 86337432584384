import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core';
import MiniDrawer from '../../navbar/Navbar'
import auth from '../../../firebase/firebaseConfig'
import { css } from "@emotion/core";
import {Alert} from '@material-ui/lab'
import {PropagateLoader} from "react-spinners";
import {Redirect, Link} from 'react-router-dom'
const useStyles = makeStyles((theme)=>({
    log:{
        height:140,
        width:100,
    },
    form:{
        height:'100%',
        width:'100%',
        marginTop:"20vh",
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-evenly',
    },
    TextField:{
        marginBottom:"3vh"
    },
    h4:{
        paddingTop:"1vh"
    }
}));

export default function Login() {
    const override = css`
    display: block;
    margin-left:50%;
   `;
    const classes = useStyles();

    const [state,setState]=useState({
        email:'',
        password:''
    });
    const [loading, setLoading] = useState(false)
    const [redirect, setRedirect] = useState(false)

    const handleChange=(e)=>{
        setState(
            {
                ...state,
                [e.target.name]:e.target.value
            }
        )
        
    }

    const loginInUser = (email, password) => {
        setLoading(true)

        auth.signInWithEmailAndPassword(email, password)
          .then(
            (response) => {
                if(response.user){
                    console.log(response.user)
                    localStorage.setItem("isAuth", true)
                   // setLoading(false)
                    setRedirect(true)
                }else{
                    console.log(response)
                }
            }
          )
          .catch(
            (err) => {
              console.log(err)
            }
          )
      }
    const handleLogIn=(e)=>{
        e.preventDefault();
        loginInUser(state.email, state.password)
    }
    const performRedirect = () => {
        if(redirect){
                return <Redirect to='/dashboard'></Redirect> 
        }
    }


  const Flash = () => {
    // if(error){
    //     return  <Alert  severity="error"><span className="flash">{error}</span></Alert>
    // }
    if(loading === true){
        return <PropagateLoader
        css={override}
        size={22}
        color={"blue"}
       loading={loading}
      />
    }
}


    return (
        <>
        <MiniDrawer/>
        <Box display="flex" justifyContent="center" flexGrow='1' alignItems="center">
            <Box  display="flex" flexDirection="column" p={2} width={[2/3,1/3,1/4]} height="50%">
            {Flash()}

            <form className={classes.form} >
            <TextField className={classes.TextField} id="outlined-basic" required name="email" type="email" onChange={handleChange} label="Email" variant="outlined" />
            <TextField className={classes.TextField} id="outlined-basic" required name="password" type="password" onChange={handleChange} label="Password" variant="outlined" />
            <Button variant="contained" type="button" color="primary" onClick={handleLogIn} > Login</Button>
            <h6 className={classes.h4} >Not a user? <Link to="/signup">Signup</Link></h6>
            </form>
            {performRedirect()}
            </Box>                
        </Box>
        </>

    )
}