

import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import auth from '../../firebase/firebaseConfig'

import {Link, Redirect} from 'react-router-dom'
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  link:{
    textDecoration:"none"
  }
}));


export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isAuth, setAuth] = useState(false)
  const [redirect, setRedirect] = useState(false)
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
        if(user){
          setAuth(true)
        }else{
          setAuth(false)
        }
    }) 
  }, [])
  
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const performRedirect = () => {
    if(redirect){
            return <Redirect to='/'></Redirect> 
    }
}

 const  signOut = () => {
    auth.signOut()
        .then(() => {
            localStorage.clear()
            setRedirect(true)
            console.log("loggedOut")
            return true
        }).catch((err) => {
            console.log("error in Logout" + err)
            return false
        })
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Link style={{color:"white", textDecoration:"none"}} to="/">
            <Typography variant="h6" noWrap>
              OPS Dashboard
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link className={classes.link} to="/dashboard">
          <ListItem button>
              <ListItemIcon><DashboardIcon/></ListItemIcon>
              <ListItemText primary="Deals" />
            </ListItem>
          </Link>

          <Link className={classes.link} to="/coupons">
          <ListItem button>
              <ListItemIcon><LocalOfferIcon/></ListItemIcon>
              <ListItemText primary="Coupons" />
            </ListItem>
          </Link>
          
            {isAuth ? 
                <ListItem button onClick={signOut}>
                  <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem> 
              : 
              <Link to="/signup" className={classes.link}>
                <ListItem button>
                  <ListItemIcon><PersonAddIcon/></ListItemIcon>
                  <ListItemText primary="Signup" />
                </ListItem>
              </Link> 
             }
        </List>
        {performRedirect()}

        <Divider />

      </Drawer>
     
    </div>
  );
}