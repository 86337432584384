import React, {useState} from 'react'
import firebase from 'firebase/app'

import { css } from "@emotion/core";
import {PropagateLoader} from "react-spinners";
import './dashboard.css'


 const DealForm = (props) => {
    const override = css`
    display: block;
    margin-left:50%;
    margin-bottom:5%
   `;


    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        customerName:"",
        contactNumber:"",
        resourceID:"",
        serviceName:"",
        amount:"",
        paymentStatus:""
    })
    const {customerName, contactNumber, resourceID, serviceName, amount, paymentStatus} = state;
    const handleChange = name => event => {
        setState({...state, [name]: event.target.value})
    }
    const onSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        const data = {
            customerName:customerName,
            contactNumber:contactNumber,
            resourceID:resourceID,
            serviceName:serviceName,
            amount:amount,
            paymentStatus:paymentStatus
        }
        console.log(data)
        var functions = firebase.app().functions('asia-east2');
        const createOrder = functions.httpsCallable('createOrder');
        createOrder(data)
            .then((response) => {
                console.log(response)
                setLoading(true)
                props.setOpen(false)
                props.setSaved(true)
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <form>
                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="customerName">Customer Name</label>
                        <input type="text" onChange={handleChange("customerName")} class="form-control" id="customerName"  />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="contactNumber">Contact Number</label>
                        <input type="text" onChange={handleChange("contactNumber")} class="form-control" id="contactNumber" />
                    </div>
                </div>

                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="resourceID">Resource ID</label>
                        <input type="text" onChange={handleChange("resourceID")} class="form-control" id="resourceID"  />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="serviceName">Service Name</label>
                        <input  type="text" onChange={handleChange("serviceName")} class="form-control" id="serviceName"  />
                    </div>
                </div>

                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="amount">Amount</label>
                        <input type="number" onChange={handleChange("amount")} class="form-control" id="amount"  />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="paymentStatus">Payment Status</label>
                        <select onChange={handleChange("paymentStatus")}  id="inputState" class="form-control">
                            <option value="paid">Paid</option>
                            <option value="pending">Pending</option>
                        </select>
                    </div>
                </div>
                {
                    loading ? 
                    <button type="submit" disabled  style={{float:"right"}} class="btn btn-primary">Save</button>
                    :
                    <button type="submit" onClick={onSubmit}  style={{float:"right"}} class="btn btn-primary">Save</button>
                }
                {loading &&     <PropagateLoader
                    css={override}
                    size={22}
                    color={"blue"}
                    loading={loading}
                  />}
            </form>
        </div>
    )
}

export default DealForm