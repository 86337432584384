
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MiniDrawer from '../navbar/Navbar'
import Button from '@material-ui/core/Button';

import { Modal } from 'react-responsive-modal';
import '../../../node_modules/react-responsive-modal/styles.css'
import firebase from 'firebase/app'

import BootstrapTable from "react-bootstrap-table-next";
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';

import { css } from "@emotion/core";
import {PropagateLoader} from "react-spinners";
import './dashboard.css'

import Form from './editForm'
 import DealForm from './couponForm' 
export default function Dashboard() {
  const override = css`
  display: block;
  margin-left:50%;
 `;

    const [open, setOpen] = React.useState(false);
    const [openForm, setOpenForm] = React.useState(false);
    const [data, setData] = React.useState("")
    const [ dealValue, setDealValue] = useState("")
    const [loading, setLoading] = useState(false)
    const [saved, setSaved] = useState(false)
    const [error, setError] = useState("")
    const [verified, setVerified] = useState("")
    const [user, setUser] = useState("")
    const [emailSent, setEmailSent] = useState("")
    const [dummy, setDummy] = useState(false)
    const [role, setRole] = useState("")

    useEffect(() => {
      
      var functions = firebase.app().functions('asia-east2');
      setLoading(true)
      const getCoupons = functions.httpsCallable('getCoupons');
      getCoupons()
        .then((response) => {
          setRole(response.data.role)  
         
          setData(response.data.coupons)
          setLoading(false)
        })
       .catch( (err)=>{setError(err.code) 
        console.log((err))
        setLoading(false)}) 
        checkMail()
    }, [saved, dummy])




    useEffect ( () =>{
      firebase.auth().onAuthStateChanged((user) => {
        if(user) {
          setVerified(user.emailVerified)
          setUser(user)
     }
        else console.log(0)
       })
    }, [])


    const checkMail = () => {
      if(localStorage.getItem("email") === null){
        setEmailSent(false)
      }else{
        setEmailSent(true)
      }
    }

    const onOpenModal = (rowData) => {
      setOpen(true);
      setDealValue(rowData)
    };
   
     const onCloseModal = () => {
      setOpen(false);
    };

    const onOpenForm = () => {
      setOpenForm(true);
    };
   
     const onCloseForm = () => {
      setOpenForm(false);
    };

    const handleDeleteCoupon = (id) => {
      setLoading(true)
      var functions = firebase.app().functions('asia-east2');
      const deleteCoupon = functions.httpsCallable('deleteCoupon');
      const data = {
        "id": id.id
      }
      
      deleteCoupon(data)
        .then((response) => {
          setLoading(false)
          setDummy(true)
        })
       .catch( (err)=>{setError(err.code) 
        console.log((err))
      }) 
    }


       const columns = [
        {
          dataField: "details.name",
          text: "Name"
        },
        {
          dataField: "details.minimumTransaction",
          text: "Min. Transaction",
        },
        {
          dataField:"details.type",
          text:"Type"
        },
        {
          dataField:"details.discount",
          text:"Discount",
          sort:true
        },
        {
            dataField: "id",
            text: "Operations",
            formatter: (cell, row) => {
              switch(role) {
                case 'ops':
                  return "-"
                  break;
                case "admin":
                  return (
                    <>
                      <EditTwoToneIcon  style={{fontSize:"25", cursor:"pointer"}} onClick={() =>onOpenModal(row)}/>
                      <DeleteForeverTwoToneIcon  style={{fontSize:"25", cursor:"pointer"}} onClick={() =>handleDeleteCoupon(row)}/>
                    </>
      
                    )
                  break;
                default: 
                    return "-"
              }
            }
          }
       ];

       const sendVerification = () => {
         console.log("sent")
          user.sendEmailVerification()
          setEmailSent(true)
          localStorage.setItem("email", true)
          setDummy(true)
       }

      
    return (
        <Fragment>
        {console.log(emailSent)}
            <MiniDrawer/>
            {console.log(error)}
            <div style={{marginTop:"10vh",maxWidth:"80%", marginLeft:"20ch"}}>
                {
                  error ? 
                  <>
                  <h1>{error.toUpperCase()}</h1>
                  <p>Is your email verified?</p>
                  <p>Or try logging in again</p>
                  {
                    (!verified) &&
                    emailSent ?
                      <>
                      <h4>Please check your mail to verify</h4>
                      <h6>If you still see permission denied please do a re-login</h6>
                      </>
                     :
                    <button onClick={sendVerification} className="verification">Send Verification email</button> 

                  }
                  </>
                  :
                  loading ?
                  <PropagateLoader
                    css={override}
                    size={22}
                    color={"blue"}
                    loading={loading}
                  />
                   : 
                   <>
                   <h1>Coupons</h1>

                   {role === "admin" &&
                   <Button 
                      className="create-button" 
                      style={{marginBottom:"1.5vh"}} 
                      variant="contained" 
                      type="button" 
                      color="primary" 
                      onClick={onOpenForm} 
                      >
                      Create Coupon
                    </Button>}

                  <BootstrapTable
                    keyField="id"
                    data={data}
                    columns={columns}
                    striped
                    hover
                    condensed
                />   
                   </>   
                }
            </div>

            <Modal open={open} onClose={onCloseModal} center>
                <h2>Edit Deal Data</h2>
                <Form dealValue={dealValue} setSaved={setSaved} setOpen={setOpen} />
            </Modal>

            <Modal open={openForm} onClose={onCloseForm} center>
                <h2>Create New Deal</h2>
                <DealForm  setSaved={setSaved} setOpen={setOpenForm} />
            </Modal>
        </Fragment>
    )
}
