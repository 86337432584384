import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import auth from './firebaseConfig'
const PrivateRoutes = ({component: Component, ...rest}) => {


    const isAuth = localStorage.getItem("isAuth")
    return (
        <Route
        {...rest}
        render={props => 
            isAuth ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state:{from : props.location}
                    }}
                    />
            )
        }
        />
    )
}

export default PrivateRoutes