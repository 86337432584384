import React, {useState} from 'react'
import firebase from 'firebase/app'

import { css } from "@emotion/core";
import {PropagateLoader} from "react-spinners";
import './dashboard.css'


 const DealForm = (props) => {
    const override = css`
    display: block;
    margin-left:50%;
    margin-bottom:5%
   `;


    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        discount:"",
        minimumTransaction:"",
        name:"",
        type:""
    })
    const {discount, minimumTransaction, name, type} = state;
    const handleChange = name => event => {
        setState({...state, [name]: event.target.value})
    }
    const onSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        const data = {
           discount: discount,
           minimumTransaction: minimumTransaction,
           name: name,
           type: type
        }
        console.log(data)
        var functions = firebase.app().functions('asia-east2');
        const createCoupon = functions.httpsCallable('createCoupon');
        createCoupon(data)
            .then((response) => {
                console.log(response)
                setLoading(true)
                props.setOpen(false)
                props.setSaved(true)
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <form>
                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="discount">Discount</label>
                        <input type="text" onChange={handleChange("discount")} class="form-control" id="discount"  />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="minimumTransaction">Minimum Transaction</label>
                        <input type="text" onChange={handleChange("minimumTransaction")} class="form-control" id="minimumTransaction" />
                    </div>
                </div>

                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="name">Name</label>
                        <input type="text" onChange={handleChange("name")} class="form-control" id="name"  />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="type">Coupon Type</label>
                        <select onChange={handleChange("type")}  id="type" class="form-control">
                        <option value="">Select</option>
                            <option value="FLAT">Flat</option>
                            <option value="PERCENT">Percent</option>
                        </select>
                    </div>
                </div>


                {
                    loading ? 
                    <button type="submit" disabled  style={{float:"right"}} class="btn btn-primary">Save</button>
                    :
                    <button type="submit" onClick={onSubmit}  style={{float:"right"}} class="btn btn-primary">Save</button>
                }
                {loading &&     <PropagateLoader
                    css={override}
                    size={22}
                    color={"blue"}
                    loading={loading}
                  />}
            </form>
        </div>
    )
}

export default DealForm