import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import Dashboard from './components/dashboard/Dashboard'
import Login from './components/Auth/login/Login'
import Signup from './components/Auth/registerform/RegisterForm'
import PrivateRoutes from './firebase/privateRoute'
import AuthRoutes from './firebase/loginToDashboard'
import SignUpRoute from './firebase/noSignup'
import CouponDashboard from './components/CouponDashboard/Dashboard'
const Routes = () => {
    return(
        <BrowserRouter>
        <Switch>
            <AuthRoutes path="/" exact component={Login} />
            <SignUpRoute path="/signup" exact component={Signup} />
            <PrivateRoutes path="/dashboard" exact component={Dashboard} />
            <PrivateRoutes path="/coupons" exact component={CouponDashboard} />
         </Switch>
    </BrowserRouter>
    )
}
export default Routes