import firebase from 'firebase'





const firebaseConfig = {
    apiKey: "AIzaSyB7a00IfzsWcO6fvDh5c0zoZYCbmzTgldw",
    authDomain: "dev-internal-dashboard-evrcare.firebaseapp.com",
    databaseURL: "https://dev-internal-dashboard-evrcare.firebaseio.com",
    projectId: "dev-internal-dashboard-evrcare",
    storageBucket: "dev-internal-dashboard-evrcare.appspot.com",
    messagingSenderId: "191159231738",
    appId: "1:191159231738:web:480e5bcfb2ad473e431543"
  };
// const firebaseConfig={
//     apiKey: "AIzaSyDIAQiHnxVdHi0e1zTtDbgp3LXPGd8NQd4",
//     authDomain: "evrcare-dashboard.firebaseapp.com",
//     databaseURL: "https://evrcare-dashboard.firebaseio.com",
//     projectId: "evrcare-dashboard",
//     storageBucket: "evrcare-dashboard.appspot.com",
//     messagingSenderId: "182563893102",
//     appId: "1:182563893102:web:e4b76d199f857ab9925b7f",
//     measurementId: "G-PJHGXC5RKM"
 
// };


 const app = firebase.initializeApp(firebaseConfig);
 const auth = app.auth();

 export default auth