import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import auth from './firebaseConfig'
const AuthRoutes = ({component: Component, ...rest}) => {


    const isAuth = localStorage.getItem("isAuth")
    return (
        <Route
        {...rest}
        render={props => 
            isAuth ? (
               <Redirect
                    to={{
                        pathname: '/dashboard',
                        state:{from : props.location}
                    }}
                    />
            ) : (
                <Component {...props} />
            )
        }
        />
    )
}

export default AuthRoutes