import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Box, makeStyles} from '@material-ui/core'
import MiniDrawer from '../../navbar/Navbar'
import {Redirect} from 'react-router-dom'
import auth from '../../../firebase/firebaseConfig'
import { css } from "@emotion/core";

import {PropagateLoader} from "react-spinners";
const useStyles = makeStyles((theme)=>({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  log:{
      height:140,
      width:100,
  },
  form:{
      height:'100%',
      width:'100%',
      marginTop:"20vh",
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-evenly',
  },
  TextField:{
      marginBottom:"3vh"
  }
}));



export default function RegisterForm() {
  const override = css`
  display: block;
  margin-left:50%;
 `;

  const classes = useStyles();
    const [state,setState] = useState(
      {
        name:'',
        eid:'',
        email:'',
        password:'',
        cnfpass:'',
        message:'',
        didRedirect:false,
      }
    )
    const [loading, setLoading] = useState(false)

     const createUser = (name, email, pass, eid) => {
      setLoading(true)
      auth.createUserWithEmailAndPassword(email, pass)
        .then(
          (response) => {
            if (response.user) {
              response.user.updateProfile({
                displayName: name,
                photoURL: eid
              })
              localStorage.setItem("isAuth", true)
              setState({...state, didRedirect:true})
              setLoading(false)

              console.log(response.user)
            }
          }
        )
        .catch(err => console.log(err))
          
    }
    const handleSubmit =(e)=>{
      createUser(state.name,state.email,state.password,state.eid)
        
      e.preventDefault();
    }

    const handleChange= (e)=>{
      setState({
        ...state,
        [e.target.name] : e.target.value
      })
    }

    const performRedirect = () => {
      if(state.didRedirect){
              return <Redirect to='/dashboard'></Redirect> 
      }
  }


  const Flash = () => {
    // if(error){
    //     return  <Alert  severity="error"><span className="flash">{error}</span></Alert>
    // }
    if(loading === true){
        return <PropagateLoader
        css={override}
        size={22}
        color={"blue"}
        loading={loading}
      />
    }
}

    return (
     
      <div>
        <MiniDrawer/>
        <Box display="flex" justifyContent="center" flexGrow='1' alignItems="center">
            <Box  display="flex" flexDirection="column" p={2} width={[2/3,1/3,1/4]} height="50%">
            <form className={classes.form} >
            {Flash()}

            <TextField

            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            onChange={handleChange}
            value={state.name}
          />
          <TextField
            margin="dense"
            name="eid"
            label="Employee ID"
            type="text"
            fullWidth
            onChange={handleChange}
            value={state.eid}
          />
           <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            onChange={handleChange}
            value={state.email}
          />
          <TextField
           margin="dense"
           name="password"
           label="Password"
           type="password"
           fullWidth
           onChange={handleChange}
           value={state.password}
         />
           <TextField
            margin="dense"
            name="cnfpass"
            label="confirm password"
            type="password"
            fullWidth
            onChange={handleChange}
            value={state.cnfpass}
          />
            <Button variant="contained" type="button" color="primary" onClick={handleSubmit}>Sign Up</Button>
            </form>
            {performRedirect()}
            </Box>                
        </Box>
      </div>
    )
}
