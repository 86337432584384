import React, {useState} from 'react'
import firebase from 'firebase/app'

import { css } from "@emotion/core";
import {PropagateLoader} from "react-spinners";
import './dashboard.css'


 const Form = (props) => {
    const override = css`
    display: block;
    margin-left:50%;
    margin-bottom:5%
   `;


    const [paymentStatus, setPaymentStatus] = useState(props.dealValue.details.paymentStatus)
    const [serviceName, setServiceName] = useState(props.dealValue.details.serviceName)
    const [loading, setLoading] = useState(false)



    const handleStatus =  event => {
        setPaymentStatus(event.target.value)
    }
    const handleServiceName = event => {
        setServiceName(event.target.value)
    }

    const onSubmit = event => {
        setLoading(true)
        event.preventDefault()
        const data = {
            id:props.dealValue.id,
            data:{
                paymentStatus:paymentStatus,
                serviceName:serviceName
            }
        }
        var functions = firebase.app().functions('asia-east2');
        const updateOrder = functions.httpsCallable('updateOrder');
        updateOrder(data)
        .then(response => {
            console.log(response)
            setLoading(false)
            props.setSaved(true)
            props.setOpen(false)
        }).catch(err => console.log(err))
    }


    return (
        <div>
            <form>
                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="customerName">Customer Name</label>
                        <input type="text" class="form-control" id="customerName" disabled value={props.dealValue.details.customerName} />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="contactNumber">Contact Number</label>
                        <input type="text" class="form-control" id="contactNumber" disabled value={props.dealValue.details.contactNumber} />
                    </div>
                </div>

                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="resourceID">Resource ID</label>
                        <input type="text" class="form-control" id="resourceID" disabled value={props.dealValue.details.resourceID} />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="serviceName">Service Name</label>
                        <input onChange={handleServiceName} type="text" class="form-control" id="serviceName"  value={serviceName} />
                    </div>
                </div>

                <div className="form-row">
                    <div class="form-group col-md-6">
                        <label for="amount">Amount</label>
                        <input type="number" class="form-control" id="amount" disabled value={props.dealValue.details.amount} />
                        </div>
                        <div class="form-group col-md-6">
                        <label for="paymentStatus">Payment Status</label>
                        <select onChange={handleStatus} id="inputState" class="form-control">
                             <option value="paid">Paid</option>
                            <option value="pending">Pending</option>
                        </select>
                    </div>
                    {console.log(serviceName)}
                </div>
                {
                    loading ? 
                    <button type="submit" disabled  style={{float:"right"}} class="btn btn-primary">Save</button>
                    :
                    <button type="submit" onClick={onSubmit} style={{float:"right"}} class="btn btn-primary">Save</button>
                }
                {loading &&     <PropagateLoader
                    css={override}
                    size={22}
                    color={"blue"}
                    loading={loading}
                  />}
            </form>
        </div>
    )
}

export default Form